.carousel {

}

.carousel__grid {
 position: relative;
 padding-bottom: 60px;
}

.carousel__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carousel__item {
  position: relative;
  width: 600px;
}
.carousel__item.hidden{
  display: none;
}
.carousel__item img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px #999;
}
.carousel__item p {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  display: block;
  width: 100%;
  text-align: center;
  background: none;
}

.arrow {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--clr-primary);;
  cursor: pointer;
  background: none;
  z-index: 10;
}
.arrow.prev {
  left: 1rem;
}
.arrow.next {
  right: 1rem;
}

.indicators {
  background: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.indicator {
  background-color: white;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  border: 2px solid #999;
  outline: none;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator.inactive {
  background-color: grey;
}

@media (max-width: 600px) {
  .carousel__item {
    width: 100%;
  }
}
